<template>
  <b-card>
    <b-card-body>
      <b-card-title>{{ $t("menu_name.tutorial") }}</b-card-title>
      <b-card-body>
        <b-row style="margin-bottom: 15px;">
          <b-col cols="6">
            <div
              style="font-size: medium; font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 10px;"
            >
              {{ $t("tutorial.title1") }}
            </div>
            <b-embed
              type="iframe"
              src="https://www.youtube.com/embed/wAiaCtVcqQM"
            />
            <div style="margin-top: 15px;">
              {{ $t("tutorial.desc1") }}
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BEmbed,
    BFormTextarea,
  },
};
</script>
